import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import * as s from '~/components/pages/top/top.module.scss'
import Logo from '../images/mv_logo_bk.svg'
import { Seo, Features, BeforeAfter, Plan, Faq, NewsList } from '~/components'
import Questions from '~/json/faq.json'

const IndexPage = () => {
  const faq = Questions.top_faq

  return (
    <>
      <Seo
        title="靴職人が１足１足を丁寧にキレイにする『スニーカーウォッシュ』専門店Snish(スニッシュ)"
        pageUrl=""
      />
      <div>
        <div id="mainVisual" className={s.mainVisual}>
          <div className={s.mainVisualImg}>
            <StaticImage
              src="../images/top_mv_snish_wash.jpg"
              alt=""
              className="hidden-phone"
              placeholder="blurred"
              layout="fullWidth"
            />
            <StaticImage
              src="../images/top_mv_snish_wash_sp.jpg"
              alt=""
              className="visible-phone"
              placeholder="blurred"
              layout="fullWidth"
            />
          </div>
          <div className={s.mainVisualTitle}>
            <p>自分にとっての心地いいが、地球に優しい未来をつくる</p>
            <div role="img" aria-label="SNISH">
              <Logo />
            </div>
          </div>
        </div>
        <section className={s.topContent}>
          <h2 className={s.topLeadTxt + ' heading is-primary'}>
            SNISHは、あなたの靴を
            <br className="visible-phone" />
            蘇らせるサービスです。
          </h2>

          <section id="topFeature">
            <Features />
          </section>

          <section id="topBeforeAfter" className={s.beforeAfterSection}>
            <BeforeAfter />
          </section>

          {/*
          <section id="topCustom" className={s.customSection}>
            <header className="contentComponent">
              <h3 className="heading with-english"><span>カスタムスニーカー</span>CUSTOM SNEAKERS</h3>
              <p>あなたのスニーカーをカスタマイズで世界に一つだけのものに</p>
            </header>
            <div className="contentComponent columnComponent is-3column">
              <div className={s.slideWrap + " columnChild"}>
                <StaticImage
                  src="../images/top_mv_pc.jpg"
                  alt=""
                  placeholder="blurred"
                  layout="fullWidth" />
              </div>
              <div  className={s.slideWrap + " columnChild"}>
                <StaticImage
                  src="../images/top_mv_pc.jpg"
                  alt=""
                  placeholder="blurred"
                  layout="fullWidth" />
              </div>
              <div  className={s.slideWrap + " columnChild"}>
                <StaticImage
                  src="../images/top_mv_pc.jpg"
                  alt=""
                  placeholder="blurred"
                  layout="fullWidth" />
              </div>
            </div>
          </section>
          */}

          <section id="topAction" className={s.actionSection}>
            <header>
              <h3
                className={
                  s.topSectionTitle + ' contentComponent heading with-english'
                }
              >
                <span>取り組み事例</span>ACTION
              </h3>
            </header>
            <dl className={s.actionDetail + ' contentComponent'}>
              <div>
                <dt className="heading is-primary">環境への配慮</dt>
                <dd>
                  SNISHでは、日頃中々手が回らないスニーカーケアをお手伝いさせていただくことで、「捨てない」「長く綺麗に履き続ける」というサステナブルな選択をお客様へご提供いたします。また、環境にも人にも優しい最高級スニーカー専用洗剤など、地球環境に配慮した製品を選んで使用しています。
                </dd>
                <dd className="visible-phone">
                  <div className={s.moreLinkWrap}>
                    <Link to="/blog/" className="moreLink">
                      <span className="heading">
                        サスティナビリティの取り組み
                      </span>
                    </Link>
                  </div>
                </dd>
              </div>
              <div>
                <dt className="heading is-primary">靴職人の未来を守る</dt>
                <dd>
                  <div
                    className={
                      s.actionDetailImg + ' columnComponent is-2column'
                    }
                  >
                    <div className="columnChild">
                      <StaticImage
                        src="../images/top_action_image_1.jpg"
                        alt=""
                        placeholder="blurred"
                        layout="fullWidth"
                      />
                    </div>
                    <div className="columnChild">
                      <StaticImage
                        src="../images/top_action_image_2.jpg"
                        alt=""
                        placeholder="blurred"
                        layout="fullWidth"
                      />
                    </div>
                  </div>
                  技術や熱意があるのに報われない。そんな職人を１人でもなくし、技術が正しく評価され、それぞれが誇りを持てる未来をつくっていくために歩み続けます。そのための勉強会や技術の共有、非効率化の大切さと効率化する線引き、そのためのシステム開発を行い、職人の未来を守っていきます。
                  <div className="moreLinkWrap hidden-phone">
                    <Link to="/blog/" className="moreLink">
                      <span className="heading">
                        サスティナビリティの取り組み
                      </span>
                    </Link>
                  </div>
                </dd>
              </div>
            </dl>
          </section>

          <section id="topInstagram" className={s.instagramSection}>
            <header>
              <h3
                className={
                  s.topSectionTitle + ' contentComponent heading with-english'
                }
              >
                <span>ピックアップ</span>Pick UP
              </h3>
              <div className={s.topImgWrap + ' contentComponent heading'}>
                <a
                  href="https://www.instagram.com/snish.jp/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <StaticImage
                    src="../images/top_Instagram_banner_a.jpg"
                    alt=""
                    placeholder="blurred"
                    layout="fullWidth"
                  />
                </a>
              </div>
              <div className={s.topImgWrap + ' contentComponent heading'}>
                <a
                  href="/sole/"
                  rel="noopener noreferrer"
                >
                  <StaticImage
                    src="../images/top_sole_banner.jpg"
                    alt=""
                    placeholder="blurred"
                    layout="fullWidth"
                  />
                </a>
              </div>
            </header>
          </section>

          <section className={s.planSection}>
            <div id="topPlan" className="anchorTarget">
              <Plan />
            </div>
          </section>

          <section id="topFlow" className={s.flowSection}>
            <header>
              <h3
                className={
                  s.topSectionTitle + ' contentComponent heading with-english'
                }
              >
                <span>ご利用の流れ</span>USER’S FLOW
              </h3>
            </header>
            <div className="contentComponent columnComponent is-2column">
              <section className="columnChild">
                <div
                  className={
                    s.topImgWrap + ' imgWrapComponent with-border heading'
                  }
                >
                  <StaticImage
                    src="../images/top_flow_image_1.jpg"
                    alt=""
                    placeholder="blurred"
                    layout="fullWidth"
                  />
                </div>
                <h4 className={s.topHeading + ' heading is-primary'}>
                  ネットでご注文＆集荷依頼メール到着
                </h4>
                <p>
                  ご注文画面よりご希望のサービスをお選びください。決済まで終えて頂くと、ご注文完了メールと、お靴を発送するための集荷依頼メールが届きます。（届いていない場合は、迷惑メールフォルダなどご確認をお願い致します。靴をお送りいただく際には、お手数ですがお客様に箱をご準備いただく形となります）
                </p>
              </section>
              <section className="columnChild">
                <div className={s.topImgWrap + ' imgWrapComponent with-border'}>
                  <StaticImage
                    src="../images/top_flow_image_2.jpg"
                    alt=""
                    placeholder="blurred"
                    layout="fullWidth"
                  />
                </div>
                <h4 className={s.topHeading + ' heading is-primary'}>
                  スニーカーを工房へ発送
                </h4>
                <p>
                  ご注文後、発送の準備をお願い致します。ご自宅への集荷、コンビニ窓口やPUDロッカー発送などお客様のご都合に合わせて発送方法をお選び頂けます。発送方法の詳細は注文メールに記載しておりますので、ご確認の上、発送をお願いいたします。（靴の箱は基本的には工房へ届いた箱でお返しいたします。また、シューキーパーも発送時に外していただきますようお願いいたします）
                </p>
              </section>
              <section className="columnChild">
                <div className={s.topImgWrap + ' imgWrapComponent with-border'}>
                  <StaticImage
                    src="../images/top_flow_image_3.jpg"
                    alt=""
                    placeholder="blurred"
                    layout="fullWidth"
                  />
                </div>
                <h4 className={s.topHeading + ' heading is-primary'}>
                  修理・メンテナンス
                </h4>
                <p>
                  工房へ到着したスニーカーは、職人によって状態を見極めながら、素材を痛めないようご希望の修理やメンテナンスが施されます（おまかせプラン含め、靴の状態を拝見後に職人がお客様にご連絡をさしあげる場合がございます。ご登録いただく連絡先は、恐れ入りますがご連絡がとれる情報でお願いいたします）。
                </p>
              </section>
              <section className="columnChild">
                <div className={s.topImgWrap + ' imgWrapComponent with-border'}>
                  <StaticImage
                    src="../images/top_flow_image_4.jpg"
                    alt=""
                    placeholder="blurred"
                    layout="fullWidth"
                  />
                </div>
                <h4 className={s.topHeading + ' heading is-primary'}>
                  お客様の元へ発送
                </h4>
                <p>
                  仕上がりましたら、最終チェック後にお客様の元へ発送いたします。環境を考慮して、基本的には工房へ届いた箱でお返しいたします。SNISHを通して、お客様がお気に入りの靴とこれからも素敵な時間を歩んでいけますように。
                </p>
              </section>
            </div>
          </section>

          <section id="topFaq">
            <header>
              <h3
                className={
                  s.topSectionTitle + ' contentComponent heading with-english'
                }
              >
                <span>よくあるご質問</span>FAQ
              </h3>
            </header>
            <div className="contentComponent">
              <Faq faq={faq} />
              <footer>
                <div className={s.topMoreLink + ' moreLinkWrap'}>
                  <Link to="/pages/faq/" className="moreLink">
                    <span className="heading is-secondary">もっと見る</span>
                  </Link>
                </div>
              </footer>
            </div>
          </section>

          <section id="topNews" className={s.newsSection}>
            <header>
              <h3
                className={
                  s.topSectionTitle + ' contentComponent heading with-english'
                }
              >
                <span>お知らせ</span>NEWS
              </h3>
            </header>
            <NewsList />
            <footer className="contentComponent">
              <div className={s.topMoreLink + ' moreLinkWrap'}>
                <Link to="/blog/" className="moreLink">
                  <span className="heading is-secondary">もっと見る</span>
                </Link>
              </div>
            </footer>
          </section>
        </section>
      </div>
    </>
  )
}
export default IndexPage
